@charset "utf-8";

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url(./fonts/Roboto-Thin.woff2) format("woff2"),
    url(./fonts/Roboto-Thin.woff) format("woff"),
    url(./fonts/Roboto-Thin.otf) format("opentype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/Roboto-Light.woff2) format("woff2"),
    url(./fonts/Roboto-Light.woff) format("woff"),
    url(./fonts/Roboto-Light.otf) format("opentype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Roboto-Regular.woff2) format("woff2"),
    url(./fonts/Roboto-Regular.woff) format("woff"),
    url(./fonts/Roboto-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/Roboto-Medium.woff2) format("woff2"),
    url(./fonts/Roboto-Medium.woff) format("woff"),
    url(./fonts/Roboto-Medium.otf) format("opentype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/Roboto-Bold.woff2) format("woff2"),
    url(./fonts/Roboto-Bold.woff) format("woff"),
    url(./fonts/Roboto-Bold.otf) format("opentype");
}
