@charset "utf-8";

// modal
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // display: none;
  z-index: 400;
  width: 100%;
  height: 100%;
  background: #87878775;
}

.modal.show {
  display: block;
}

.modal_bg {
  width: 100%;
  height: 100%;
}

.modal {
  .box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 556px;
    box-shadow: 2px 2px 10px rgba(91, 91, 91, 0.25);
    > ul {
      > li {
        color: #5b5b5b;
        margin-bottom: 30px;
        &:last-of-type {
          margin-bottom: 0;
        }
        h2 {
          margin-bottom: 30px;
        }
        label {
          margin-top: 30px;
          width: 100%;
          height: 100%;
          display: inline-block;
          cursor: pointer;
          position: relative;
        }
        input[type="file"] {
          width: 100%;
        }
        #ex_file {
          z-index: 500;
          display: inline-block;
          width: 100%;
          height: 100%;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
        }
        > ul {
          > li {
            font-size: 14px;
            line-height: 1.2;
            list-style-type: decimal;
            list-style-position: inside;
            position: relative;
          }
        }
        p {
          line-height: 1.2;
        }
        a {
          &.pointOut,
          &.point {
            line-height: 30px;
            border-radius: 5px;
            font-weight: 700;
            text-align: center;
          }
        }
        a,
        button {
          display: block;
          width: 100%;
          height: 32px;
          &.pointOut {
            margin-top: 30px;
          }
          &.back {
            margin-top: 20px;
            color: nth($gray, 3);
            background: #d9d9d9;
          }
        }
      }
    }
  }
}

.modalBox {
  width: 100%;
  box-sizing: border-box;
  max-width: 720px;
  height: 600px;
  position: relative;
  padding: 52px 68px;
  position: fixed;
  top: 50%;
  left: 50%;
  background: #fff;
  z-index: 500;
  border-radius: 8px;
  overflow: hidden;
  transform: translateX(-50%) translateY(-50%);
  &.large {
    height: 660px;
  }
  &.chart {
    max-width: 980px;
    .modal_body {
      padding: 20px 0 30px;
      .flexB {
        padding: 0 20px;
        margin-bottom: 10px;
        .apexcharts-canvas {
          height: 500px;
        }
      }
    }

    .update {
      color: #333;
      button {
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        span {
          color: #333;
        }
      }
    }
  }
  .modal_header {
    width: 100%;
    align-items: center;
    margin-bottom: 52px;
    background: #fff;
    p {
      margin-top: 24px;
      color: nth($gray, 1);
    }
    span {
      vertical-align: middle;
    }
    button {
      width: 36px;
      height: 36px;
      span {
        font-size: 24px;
        vertical-align: middle;
      }
    }
  }
  .modal_body {
    .flex {
      .ant-picker,
      input,
      select {
        // height: 40px;
      }
      .ant-picker-input {
        input {
          height: 30px;
        }
      }
    }
    > .inputBox {
      margin-bottom: 36px;
      p {
        color: nth($gray, 1);
        font-weight: 700;
        margin-bottom: 16px;
      }
      p.message {
        margin-top: 4px;
        font-size: 14px;
        font-weight: normal;
      }
      p.success {
        color: #20bf55;
      }
      p.error {
        color: nth($red, 2);
        margin-bottom: 0;
      }
      span {
        &.safe {
          margin-left: 24px;
          color: #20bf55;
          img {
            width: 20px;
            margin-right: 15px;
          }
        }
      }
      input {
        width: 304px;
        &.error {
          background: #ff436522;
        }
        + button {
          padding: 0 20px;
          height: 32px;
          margin-left: 24px;
          font-size: 16px;
          font-weight: 700;
          color: nth($gray, 3);
          background: #ededed;
        }
      }

      .ant-picker,
      input,
      select {
        // flex: 1;
      }
    }
    .buttonWrap {
      position: absolute;
      bottom: 0;
    }
    .tableBox,
    .stockBox {
      margin-top: 20px;
      max-height: 600px;
      position: relative;
      overflow-y: auto;
    }
    .stockBox {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      > .flex {
        align-items: flex-start;
        width: calc(50% - 10px);
        flex-direction: column;
        p {
          margin-bottom: 6px;
        }
        .ant-picker,
        input,
        select {
          width: 100%;
        }
      }
    }
    .tableBox {
      border: 1px solid nth($gray, 2);
      border-radius: 4px;
      table {
        th {
          position: -webkit-sticky; /* Chrome, Safari, Opera */
          position: sticky; /* 다른 브라우저 */
          top: 0;
          z-index: 2;
          background: #f4f6fa;
        }
        input {
          text-align: right;
        }
      }
    }
    .buttonWrap {
      display: flex;
      margin-top: 40px;
      justify-content: center;
      gap: 20px;
      button {
        height: 40px;
        font-weight: 500;
      }
    }
    &.list {
      height: 400px;
      overflow-y: auto;
    }
    ul {
      li {
        font-size: 16px;
        line-height: 2;
      }
    }
  }
  .buttonWrap {
    position: absolute;
    bottom: 52px;
    gap: 20px;
    display: flex;
    button {
      width: 118px;
      height: 32px !important;
    }
  }
}
.ant-modal {
  &.large {
    .ant-modal-content {
      height: 375px;
    }
  }
  .ant-modal-content {
    width: 480px;
    height: 200px;
    padding: 0 55px 24px;
    border-radius: 16px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    .ant-modal-confirm-body {
      justify-content: center;
      text-align: center;
      .anticon {
        display: none;
      }
      .ant-modal-confirm-paragraph {
        flex: unset;
        max-width: unset;
        .ant-modal-confirm-content {
          color: nth($gray, 1);
          line-height: 1.2;
          .flex {
            gap: 8px;
            align-items: flex-start;
            img {
              width: 20px;
            }
            p {
              text-align: center;
            }
            > ul {
              p {
                text-align: left;
              }
              > li {
                font-size: 14px;
                line-height: 1.2;
                text-align: left;
                list-style-type: decimal;
                list-style-position: inside;
                p {
                  text-align: left;
                }
                li {
                  padding-left: 15px;
                  list-style-position: inside;
                  list-style-type: lower-alpha;
                }
              }
            }
          }
        }
      }
    }
    .ant-modal-body {
      height: 100%;
    }
    .ant-modal-confirm-body-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      &:before,
      &:after {
        display: none;
      }
    }
    .ant-modal-confirm-body {
      align-items: center;
      flex: 1;
    }
    .ant-modal-confirm-btns {
      // position: absolute;
      // left: 50%;
      // bottom: 24px;
      // transform: translateX(-50%);
      margin-bottom: 0 !important;
      display: flex;
      justify-content: center;
      margin-top: 0;
      gap: 20px;
      .ant-btn-default,
      .ant-btn-primary {
        width: 124px;
        height: 28px;
        font-size: 16px;
        padding: 0;
        border: unset;
        border-radius: 5px;
        font-weight: 700;
        margin: 0;
      }
      .ant-btn-default {
        order: 2;
        background: #d9d9d9;
        color: nth($gray, 3);
        &:active {
          box-shadow: 4px 4px 8px 0 rgba(217, 217, 217, 0.6),
            -4px -4px 6px 0 rgba(217, 217, 217, 0.4),
            inset -4px -4px 6px 0 rgba(217, 217, 217, 0.4),
            inset 4px 4px 6px 0 rgba(217, 217, 217, 0.4);
        }
      }
      .ant-btn-primary {
        order: 1;
        background: nth($primary, 1);
        color: #fff;
        &:active {
          box-shadow: 4px 4px 6px 0 rgba(86, 67, 255, 0.3),
            -4px -4px 6px 0 rgba(86, 67, 255, 0.2),
            inset -4px -4px 6px 0 rgba(86, 67, 255, 0.2),
            inset 4px 4px 6px 0 rgba(86, 67, 255, 0.2);
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .modalBox {
    &.chart {
      width: calc(100% - 40px);
      .modal_body {
        .flexB {
          flex-direction: column;
          gap: 15px;
          align-items: unset;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 540px) {
  .modalBox {
    max-width: unset;
    width: calc(100% - 30px);
    &.chart {
      width: calc(100% - 30px);
      .modal_body {
        .flexB {
          padding: 0 15px;
        }
      }
    }
    .modal_header {
      padding: 0 15px;
    }
    .modal_body {
      padding: 20px 15px;
    }
  }
}
.menu {
  z-index: 6000;
  width: 100%;
  max-width: 540px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background: #333f50;
  .mHeader {
    padding: 0 20px;
    height: 60px;
    border-bottom: 1px solid #eee;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      a {
        font-size: 18px;
      }
    }
    button {
      span {
        color: #fff;
        font-size: 24px;
      }
    }
  }
  .mBody {
    padding: 0;
    .el-col-12 {
      width: 100%;
      margin-top: 10px;
      .el-menu {
        border-right: unset;
        .el-menu-item,
        .el-submenu,
        .el-submenu span {
          color: nth($primary, 1);
        }
      }
    }
  }
  .utility {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    p {
      font-size: 14px;
      span {
        position: relative;
        padding-right: 20px;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 9.5px;
          transform: translateY(-50%);
          width: 1px;
          height: 14px;
          background: #666;
        }
      }
    }
  }
}
