@charset "utf-8";

table {
  font-size: 14px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  text-align: left;
  table-layout: fixed;
  th,
  td {
    border-bottom: 1px solid #eee;
    font-size: 14px;
    padding: 0 12px;
    &.check {
      text-overflow: unset;
      & + th,
      & + td {
        text-overflow: unset;
      }
    }
    &.bold {
      font-weight: 700;
    }
  }
  tr.bold {
    th,
    td {
      font-weight: 700;
    }
  }
  th {
    white-space: nowrap;
    font-weight: normal;
  }
  td {
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // .ellipsis {
    //   display: table;
    //   table-layout: fixed;
    //   width: 100%;
    //   white-space: nowrap;
    // }
    // .ellipsis > * {
    //   display: table-cell;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    // }
    button.table {
      line-height: unset;
      border: 1px solid #dcdfe6;
      color: #606266;
      padding: 6px 10px;
      &.point {
        color: #409eff;
        background: #ecf5ff;
        border-color: #b3d8ff;
        // border: none;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  table {
    th,
    td {
      padding: 0 10px;
      &.bold {
        font-weight: 700;
      }
    }
  }
}
