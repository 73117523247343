/* HTML Elements Reset */
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
form,
legend,
fieldset,
img,
ul,
ol,
li,
dl,
dt,
dd,
figure,
figcaption,
table,
tr,
th,
td,
caption,
i,
div,
span,
button,
label {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
a,
button,
input,
i {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}
input:focus {
  outline: none;
}
@media only screen and (min-width: 320px) and (max-width: 560px) {
  body {
    -webkit-text-size-adjust: none;
    /* IOS Web 가로,세로모드 폰트사이즈 변경되는것 방지 */
  }
}

body {
  // padding-bottom:80px;
}

ul,
ol,
li {
  list-style: none;
}

img,
fieldset {
  border: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

// a:-webkit-any-link:active { color:transparent; }
// h1,h2,h3,h4,h5,h6,th, strong, b  { font-weight:inherit; font-size:inherit; }
address,
em,
i {
  font-style: inherit;
}

input,
img {
  border: none;
  // border-radius: 4px;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
}
a {
  -webkit-tap-highlight-color: transparent;
}

select,
option,
textarea {
  font-family: inherit;
  font-size: inherit;
}
textarea {
  padding: 10px 20px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  resize: none;
  outline: none;
  box-sizing: border-box;
}
select,
textarea,
input,
label,
img,
button {
  vertical-align: middle;
}

input[type="radio"] {
  vertical-align: center;
  margin: 0;
  margin-right: 6px;
}
input.error {
  background: #ff436522;
}
input:focus {
  // background: #f2f2f2;
}

label {
  cursor: pointer;
}

/* HTML Elements Hiding */
.fly,
hr,
legend {
  position: absolute;
  left: -10000em;
  top: -10000em;
}

.hide {
  font-size: 6px;
  color: transparent;
}

.clearfix {
  *zoom: 1;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/* Default */
select::-ms-expand {
  display: none;
}

select {
  background: #fff url(../../public/images/arrow_down.svg) no-repeat
    calc(100% - 5px) center/20px auto;
  border: 1px solid nth($border, 1);
  -webkit-appearance: none;
  padding: 5px 25px 5px 12px;
  border-radius: 4px;
  width: 250px;
  height: 40px;
  font-size: 14px;
  outline: none;
}

option {
  background: #fff;
}

input[type="checkbox"] {
  border: none;
  width: 16px;
  height: 16px;
  border: 1px solid nth($border, 1);
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  // appearance: none;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // -o-appearance: none;
  // -ms-appearance: none;
  margin-right: 8px;
  border-radius: 4px;
}

pre {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
}

textarea::placeholder,
input::placeholder {
  font-size: inherit;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="time"] {
  padding-left: 16px;
  height: 32px;
  font-size: 14px;
  border: 1px solid nth($border, 1);
  border-radius: 5px;
  &:disabled {
    color: nth($gray, 1);
  }
  &::placeholder {
    font-size: inherit;
    color: nth($border, 1);
  }
}
