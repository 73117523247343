@charset "utf-8";
.lnb {
  width: 280px;
  padding: 28px 20px 42px;
  height: 100%;
  font-size: 14px;
  color: nth($primary, 5);
  background: #f6f5f5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .lnbLogo {
    margin: 0 auto 40px;
    text-align: center;
    position: relative;
    width: 240px;
    height: 56px;
    .addLogo {
      width: 240px;
      height: 56px;
      border: 1px dashed nth($gray, 1);
      background: #fbfbfa;
      color: nth($gray, 1);
      img {
        width: 20px;
        margin-left: 10px;
      }
    }
    .dropBox {
      display: none;
      &.visible {
        display: block;
      }
    }
    .logoDropdown {
      // display: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border: unset;
      background: unset;
      img {
        width: 20px;
        vertical-align: top;
      }
      .ant-space {
        gap: 0;
        padding: 0;
        .ant-space-item {
          height: 20px;
        }
      }
    }
    .img {
      width: 240px;
      height: 56px;
      position: relative;
      margin: 0 auto;
      overflow: hidden;
      border-radius: 5px;

      > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
        &.cover {
          width: 100%;
          object-fit: cover;
        }
        &.contain {
          height: 100%;
          object-fit: contain;
        }
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        // width: auto;
        // height: auto;
        // max-width: none;
        // max-height: none;
        // object-fit: contain; /* 이미지를 가로세로 비율을 유지하며 전부 표시 */
        // object-position: center; /* 이미지를 요소 중앙에 위치시킴 */
      }
    }
    label {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: inline-block;
      padding: 0 !important;
      cursor: pointer;
      button {
        font-size: 14px;
        cursor: pointer;
      }
    }
    input[type="file"] {
      width: 240px;
    }
    #ex_file {
      z-index: 500;
      display: inline-block;
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
    input[type="text"] {
      display: none;
    }
  }
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: overlay;
    overflow-x: hidden;
    .top {
      margin-bottom: 40px;
    }
  }
  .bottom {
    width: 100%;
  }
  p.authority {
    padding-left: 20px;
    color: nth($gray, 3);
    font-weight: 700;
    margin-bottom: 14px;
  }
  .ant-menu-root {
    width: 100% !important;
    color: nth($gray, 1);
    background: #f6f5f5;
    box-sizing: border-box;
    border-inline-end: unset !important;
    > li {
      margin-bottom: 6px !important;
      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }
    .ant-menu-sub {
      background: unset !important;
      > li {
        margin-top: 4px;
      }
    }
    &:hover {
      .ant-menu-title-content {
        color: nth($gray, 1);
      }
    }
    .ant-menu-title-content {
      color: nth($gray, 1);
      p {
        font-size: 14px;
        img {
          width: 20px;
          margin-right: 12px;
        }
      }
    }
    li {
      width: 100%;
    }
    > li.ant-menu-item {
      button.flex,
      a.flex {
        font-size: 14px;
        color: nth($gray, 1);
        img {
          margin-right: 12px;
        }
      }
    }
    > li.ant-menu-item.ant-menu-item-selected {
      background: #ededed;
    }
    > li.ant-menu-item,
    .ant-menu-submenu-title {
      font-size: 16px;
      color: nth($gray, 1);
      box-sizing: border-box;
      width: 100%;
      padding-left: 20px !important;
      height: 26px;
      line-height: 26px;
      margin: 0 auto;
      font-weight: 500;
    }
    .ant-menu-item-only-child {
      width: 100%;
      margin: 0 auto;
      font-size: 14px;
      height: 26px;
      line-height: 26px;
      padding-right: 0;
      padding-left: 52px !important;
      border-radius: 7px;
      &.ant-menu-item-selected {
        background: #ededed;
        font-weight: 700;
      }
    }
  }
}
// @media screen and (max-width: 1440px) {
//   .lnb {
//     width: 200px;
//     .lnbLogo a img {
//       width: 150px;
//     }
//     .ant-menu-root {
//       width: 200px !important;
//     }
//   }
// }
