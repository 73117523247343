@charset "utf-8";

.home {
  background: #fff;
  @supports (-ms-ime-align: auto) {
    overflow: hidden;
    /* edge */
  }
  h1 {
    font-size: 30px;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .buttonWrap {
    text-align: center;
  }
}
.home {
  position: relative;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  word-break: keep-all;
  font-size: 14px;
}

.loginBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 340px;
  width: calc(100% - 40px);
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  section {
    width: 100%;
    margin: 0 auto;
    .logo {
      margin-bottom: 40px;
      img {
        width: 120px;
      }
    }
  }
  &.done {
    section {
      .logo {
        margin-bottom: 60px;
      }
      h2 {
        margin-bottom: 60px;
        text-align: center;
      }
    }
  }
  .form {
    > div {
      margin-top: 28px;
      p {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 16px;
      }
    }

    input {
      width: 100%;
      font-size: 12px;
      // &:focus {
      //   border: 1px solid nth($primary, 1);
      // }
    }
    .password {
      width: 100%;
      position: relative;
      input[type="password"],
      input[type="text"] {
        margin-bottom: 0;
      }
      input {
        padding-right: 50px;
      }
    }
    .visibleBtn {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      outline: none;
      span {
        vertical-align: middle;
        color: nth($gray, 1);
      }
    }
    .findBtn {
      font-size: 14px;
      margin: 16px 0 28px;
      display: block;
    }
    a.large,
    button.large {
      text-align: center;
      display: block;
      width: 100%;
      height: 32px;
      line-height: 32px;
      border-radius: 6px;
      background: nth($primary, 1);
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      // transition: all 0.3s ease;
      // &:hover {
      //   background: #e05514;
      // }
    }
  }
  .right {
    height: 100%;
    // background: #eaf3f4 url(../assets/images/login_bg.png) no-repeat center;
  }
}
p.copy {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -30px);
  color: #aaa;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .home {
    background: #fff;
  }
  .loginBox {
    width: 100%;
    height: unset;
    position: static;
    transform: none;
    box-shadow: none;
    padding: 100px 20px 0;
    &.done {
      section {
        .logo {
          margin-bottom: 40px;
        }
        h2 {
          text-align: left;
        }
        a.large {
          width: calc(100% - 40px);
          position: fixed;
          left: 50%;
          transform: translateX(-50%);
          bottom: 60px;
        }
      }
      + p.copy {
        width: calc(100% - 40px);
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    section {
      width: 100%;
      margin-bottom: 40px;
      .form {
        > div {
          width: 100%;
        }
      }
      button.large {
        width: 100%;
      }
    }
    .right {
      display: none;
    }
  }
  p.copy {
    position: static;
    transform: none;
    text-align: center;
  }
}
@media screen and (max-width: 540px) {
  .loginBox {
    padding: 100px 15px 0;
    &.done {
      section {
        a.large {
          width: calc(100% - 30px);
        }
      }
      + p.copy {
        width: calc(100% - 30px);
      }
    }
  }
}
