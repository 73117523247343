@charset "utf-8";

$url: "/images/";
$primary: #5643ff, #647481, #173261, #f4f5f4, #605dec, #eeecff;
$border: #d9d9d9;
$gray: #647481 #dcdfe6 #000000 #4e5968 #4d4949;
$blue: #409efb #b3d8ff #ecf5ff;
$status: #67c23a #ffc041 #f56c6c;
$error: #f56c6c #ff616d;
$live: #0790ff;
$red: #f56c6c #ff0000;
@import "reset", "font", "navbar", "table", "modal", "home", "admin";

body {
  font-size: 16px;
  color: nth($gray, 4);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
h1,
h2,
h3,
h4 {
  font-weight: 700;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
  color: nth($gray, 1);
}
h4 {
  font-size: 18px;
}
p {
  font-size: 14px;
  &.bold {
    font-weight: bold;
  }
}
.flex,
.flexL,
.flexR,
.flexB {
  display: flex;
  align-items: center;
}
.flexL {
  justify-content: flex-start;
  flex-wrap: wrap;
}
.flexR {
  justify-content: flex-end;
  flex-wrap: wrap;
}
.flexB {
  justify-content: space-between;
}
.flr {
  float: right;
}
.flr::after {
  clear: both;
  display: block;
  content: "";
}
.tac {
  text-align: center !important;
}
button.point,
button.gray {
  font-size: 16px;
}
a.point,
a.pointOut,
button.point,
button.gray,
button.pointOut {
  font-weight: 700;
  font-size: 16px;
}
a.point,
button.point {
  background: nth($primary, 1);
  color: #fff;
  &:active {
    box-shadow: 4px 4px 6px 0 rgba(86, 67, 255, 0.3),
      -4px -4px 6px 0 rgba(86, 67, 255, 0.2),
      inset -4px -4px 6px 0 rgba(86, 67, 255, 0.2),
      inset 4px 4px 6px 0 rgba(86, 67, 255, 0.2);
  }
}
a.pointOut,
button.pointOut {
  border: 1px solid nth($primary, 1);
  color: nth($primary, 1);
  background: #fff;
  &:active {
    box-shadow: 4px 4px 6px 0 rgba(86, 67, 255, 0.3),
      -4px -4px 6px 0 rgba(86, 67, 255, 0.2);

    // box-shadow: 0 0 0 12px transparent;
    // animation: pulse 0.5s;
  }
}
button.gray {
  &:active {
    box-shadow: 4px 4px 8px 0 rgba(237, 237, 237, 0.6),
      -4px -4px 6px 0 rgba(237, 237, 237, 0.4),
      inset -4px -4px 6px 0 rgba(237, 237, 237, 0.4),
      inset 4px 4px 6px 0 rgba(237, 237, 237, 0.4);
  }
}
button.back,
a.back {
  &:active {
    box-shadow: 4px 4px 8px 0 rgba(217, 217, 217, 0.6),
      -4px -4px 6px 0 rgba(217, 217, 217, 0.4),
      inset -4px -4px 6px 0 rgba(217, 217, 217, 0.4),
      inset 4px 4px 6px 0 rgba(217, 217, 217, 0.4);
  }
}
// @keyframes pulse {
//   from {
//     box-shadow: 0 0 0 0 #5643ff;
//   }
// }
button.gray {
  background: #ededed;
  color: nth($gray, 3);
}
.primary {
  color: nth($primary, 1);
  font-weight: 700;
  .ant-checkbox-group {
    label {
      color: nth($primary, 1);
    }
  }
}
button {
  &:disabled {
    background: nth($gray, 2);
    color: nth($gray, 1);
    cursor: not-allowed;
    border: none;
  }
}
.nodata {
  text-align: center;
  padding: 100px 0;
  font-size: 14px;
}
.red {
  color: nth($red, 2);
}
.mt10 {
  margin-top: 10px;
}
.pb100 {
  padding-bottom: 100px !important;
}
.pb120 {
  padding-bottom: 120px !important;
}
.box {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 8px;
}
.contWrap {
  background: #fff;
  min-width: 1280px;
  .buttonWrap {
    button {
      height: 28px;
    }
  }
  button {
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    padding: 0;
    &.loading {
      img {
        animation: rotate 0.5s 2;
      }
    }
    &.basic {
      width: 150px;
      background: #333;
      color: #fff;
      border: 1px solid #333;
    }
    &.basicOut {
      width: 150px;
      background: #fff;
      border: 1px solid #333;
      color: #333;
    }
    &.line {
      width: 120px;
      background: #fff;
      border: 1px solid nth($gray, 2);
      color: #333;
    }
    a {
      display: block;
      line-height: 36px;
    }
  }
  &.admin {
    min-width: unset;
    margin: 0 auto;
    max-width: 1132px;
    box-sizing: border-box;
    .contents {
      width: 100%;
      margin: 0 auto;
      padding-top: 90px;
    }
    .tableBox {
      table {
        td {
          a.point {
            display: block;
            width: 86px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: #fff;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .contents {
    min-width: 1000px;
    width: calc(100% - 280px);
    margin: 0 auto 0 280px;
    padding: 50px 50px 120px;
    position: relative;
    overflow-y: auto;
    height: unset;
    min-height: 100vh;
    box-sizing: border-box;
    background: #fff;
    &.dashboard {
      padding-bottom: 80px;
      .ant-tabs {
        &.tabBoard {
          article:last-of-type {
            margin-bottom: 0;
          }
          article.log {
            margin-bottom: 40px;
          }
        }
      }
      .chartBox {
        width: 100%;
        overflow-x: auto;
        height: 600px;
        .chart {
          min-width: 1200px;
        }
      }
    }
    .title {
      // position: fixed;
      width: 100%;
      box-sizing: border-box;
      background: #fff;
      margin-bottom: 32px;
      align-items: unset;
      .utilityBtns {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .utility {
        display: block;
        line-height: unset !important;
        border-color: transparent;
        width: 22px;
        height: 22px;
        img {
          width: 20px;
        }
      }
    }
    .title + div,
    .title + article {
      // margin-top: 100px;
    }
    .title + .ant-tabs {
      margin-top: 0 !important;
    }
    .contentTitle {
      margin-bottom: 36px;
    }
  }
  pre {
    font-size: 14px;
    line-height: 40px;
  }
  .totalCount {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }
  .allSelectedBox {
    display: flex;
    background-color: nth($primary, 6);
    justify-content: center;
    padding: 8px;
    margin-bottom: 8px;
    gap: 10px;
  }
  .tableBox {
    overflow-x: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    table {
      width: 100%;
      th,
      td {
        height: 44px;
        .ant-checkbox-wrapper {
          .ant-checkbox + span {
            padding-left: 0;
            padding-right: 0;
          }
        }
        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
        &.url {
          min-width: 45px;
          overflow: unset;
          text-overflow: unset;
        }
      }

      th {
        color: nth($gray, 3);
        background: #ededed;
        border-bottom: 1px solid nth($primary, 1);
      }
      td {
        color: nth($gray, 5);
        border-bottom: 1px solid #ededed;
        height: 44px;
        &.double {
          white-space: unset;
          span {
            line-height: 1.2;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-break: break-all;
            -webkit-line-clamp: 2;
          }
        }
        a {
          font-weight: 700;
        }
        a,
        button {
          cursor: pointer;
          color: nth($primary, 1);
          &:hover {
            text-decoration: underline;
          }
        }
      }
      tr:last-of-type {
        td {
          border-bottom: unset;
        }
      }
      .filtering {
        button {
          img {
            width: 17px;
          }
          &:first-of-type {
            margin-left: 0;
          }
          margin-left: 10px;
          &.ant-btn-default {
            background: unset;
            border: unset;
            border-color: unset;
            color: unset;
            box-shadow: unset;
            height: unset;
            line-height: unset;
          }
        }
      }
    }
    &.update {
      th,
      td {
        &:first-of-type {
          width: 70px;
        }
      }
    }
    &.small {
      display: inline-block;
      table {
        width: auto;
        th,
        td {
          width: 300px;
          height: 60px;
          padding: 0 24px;
          .ant-select {
            width: 100%;
          }
        }
        th {
          .flex {
            gap: 12px;
          }
        }
        th,
        td {
          .flex {
            p,
            input {
              flex: 1;
            }
          }
          input {
            width: 100%;
            height: 28px !important;
            padding-left: 10px;
          }
        }
      }
    }
  }
  article.center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  article.center {
    &.register {
      .box {
        padding: 20px;
        width: 736px;
        box-shadow: 2px 2px 10px rgba(91, 91, 91, 0.25);
        > ul {
          > li {
            color: #5b5b5b;
            margin-bottom: 30px;
            &:last-of-type {
              margin-bottom: 0;
            }
            h2 {
              margin-bottom: 30px;
            }
            label {
              // margin-top: 30px;
              width: 100%;
              height: 100%;
              display: inline-block;
              cursor: pointer;
              position: relative;
            }
            input[type="file"] {
              width: 100%;
            }
            #ex_file {
              z-index: 500;
              display: inline-block;
              width: 100%;
              height: 100%;
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              cursor: pointer;
            }
            > ul {
              > li {
                font-size: 14px;
                line-height: 1.2;
                text-align: left;
                list-style-type: decimal;
                list-style-position: inside;
              }
            }
            p {
              line-height: 1.2;
            }
            a {
              &.pointOut,
              &.point {
                line-height: 30px;
                border-radius: 5px;
                font-weight: 700;
                text-align: center;
              }
            }
            a,
            button {
              display: block;
              width: 100%;
              height: 32px;
              &.pointOut,
              &.point {
                margin-top: 30px;
              }
            }
          }
        }
      }
    }
  }
  article.register,
  article.manage {
    .buttonWrap {
      position: absolute;
      right: 50px;
      bottom: 60px;
      gap: 20px;
      display: flex;
      button,
      a {
        width: 124px;
        text-align: center;
        font-size: 16px;
        border-radius: 5px;
        font-weight: 700;
      }
      a.pointOut {
        line-height: 26px;
      }
      button.back,
      a.back {
        height: 28px;
        line-height: 28px;
        background: #d9d9d9;
        color: nth($gray, 3);
      }
    }
  }
  article.register {
    .qnaBox {
      width: 842px;
      .ant-space-item {
        height: 24px;
      }
      .qnaDropdown,
      .replyDropdown {
        width: 28px;
        height: 28px;
        border: unset;
        background: unset;
        text-align: right;
        box-shadow: unset;
        span {
          color: #4e5968;
          vertical-align: top;
          font-size: 24px;
        }
      }
      .top {
        padding-bottom: 30px;
        border-bottom: 1px solid #d9d9d9;
        h2 {
          color: #000;
        }
      }
      .bottom {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid #d9d9d9;
      }
      p.category {
        font-size: 14px;
        margin-bottom: 15px;
        min-height: 24px;
        button {
          float: right;
        }
      }
      p.writer {
        color: #333;
        margin-top: 15px;
        span {
          padding-left: 20px;
          color: #666;
        }
      }
      pre.qnaContents {
        min-height: 200px;
        color: #000;
        margin-bottom: 36px;
      }

      pre {
        font-size: 16px;
        margin-top: 30px;
        white-space: pre-wrap;
        line-height: 1.5;
        color: #000;
      }
      .replyEdit {
        margin-top: 15px;
      }
      .replyEdit,
      .replyArea {
        border: 1px solid #969696;
        padding: 20px;
        border-radius: 5px;
        textarea {
          padding: 0;
          width: 100%;
          border: unset;
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
        }
        button {
          font-size: 16px;
          line-height: 28px;
          width: 118px;
        }
        button.back {
          background: #d9d9d9;
        }
      }
      .replyArea {
        margin-bottom: 30px;
        button {
          // position: relative;
          // .clickEffect {
          //   display: block;
          //   position: absolute;
          //   top: 1.5px;
          //   left: 1.5px;
          //   width: calc(100% - 4px);
          //   height: calc(100% - 4px);
          //   transition: opacity 0.3s ease-out;
          //   box-shadow: 0 0 0 10px transparent;
          //   animation: pulse 1s;
          // }
          // .clickEffect:not(:first-child) {
          //   display: none;
          // }
        }
      }
      .replyList {
        border-top: 1px solid #d9d9d9;
        li {
          padding: 20px;
          border-bottom: 1px solid #d9d9d9;
          > .flexB {
            height: 24px;
          }
          p.writer {
            margin-top: 0;
          }
          pre {
            margin-top: 15px;
          }
        }
      }
    }
    .registerBox {
      &.mt95 {
        margin-top: 95px;
      }
      input.white,
      textarea.white {
        &:disabled {
          background: #fff;
          color: #000;
        }
      }
      textarea {
        width: 842px;
        height: 520px;
      }
      .ant-picker {
        padding-left: 20px;
      }
      .flex {
        gap: 20px;
        margin-bottom: 36px;
        .linkBox {
          display: block;
          width: 412px;
          padding-left: 14px;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          a {
            width: 100%;
            color: nth($gray, 4);
          }
        }
        input {
          width: 196px;
        }
        input.basic {
          width: 304px;
        }
        input.large {
          width: 412px;
        }
        input.note {
          width: 628px;
        }
        .ant-select-selector .ant-select-selection-placeholder {
          font-weight: 400;
        }
        p {
          color: nth($gray, 1);
          font-weight: 700;
          margin-bottom: 16px;
          span.condition {
            font-weight: normal;
          }
        }
      }
    }
    .qnaBox,
    .registerBox {
      .links {
        .flex {
          margin-bottom: 0;
          p.linkArea {
          }
        }
      }
      p.linkArea {
        margin-bottom: 10px;
        a {
          font-weight: normal;
          color: #0957cc;
          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }
  article.manage {
    .searchBox {
      display: flex;
      gap: 16px;
      margin-bottom: 28px;
      div {
        position: relative;
        button {
          position: absolute;
          left: 8px;
          top: 50%;
          transform: translateY(-50%);
          img {
            width: 21px;
          }
        }
        input {
          // width: 268px;
          width: 400px;
          padding-left: 48px;
        }
      }
      > a {
        line-height: 32px;
        border-radius: 5px;
        text-align: center;
      }
      > a,
      > button {
        &.point,
        &.pointOut {
          width: 128px;
        }
      }
    }
    .totalCount {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 8px;
    }
  }
  .edit {
    width: 970px;
    margin-bottom: 28px;
    .flex {
      gap: 20px;
    }
    button {
      width: 124px;
      height: 32px;
      &.add {
        display: flex;
        align-items: center;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        color: nth($gray, 1);
        gap: 8px;
        img {
          width: 20px;
        }
        p {
          flex: 1;
        }
      }
    }
  }
  .sorting {
    margin-bottom: 34px;
    position: relative;
    .column,
    > .flex {
      p {
        font-weight: 700;
        margin-right: 20px;
        color: nth($gray, 1);
        &.fix {
          width: 60px;
        }
      }
    }
    > .flex {
      margin-right: 60px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .column {
      margin-bottom: 26px;
      p {
        margin-bottom: 16px;
      }
    }
  }
  .log {
    .sorting {
      position: relative;
      margin-bottom: 0;
      padding-bottom: 36px;
      margin-bottom: 38px;
      border-bottom: 2px solid #d9d9d9;
      > .flex.mb26 {
        margin-bottom: 26px;
      }
    }
    .buttons {
      position: absolute;
      bottom: 36px;
      right: 0;
      display: flex;
      gap: 20px;
      button {
        font-weight: 700;
        width: 124px;
        height: 28px;
      }
    }
  }
  .ant-tabs {
    .ant-tabs-ink-bar {
      background: nth($primary, 1) !important;
    }
    .ant-tabs-content {
      position: unset;
    }
    .ant-tabs-nav {
      margin-bottom: 40px;
      &:before {
        border-bottom: unset;
      }
      .ant-tabs-tab {
        font-size: 20px;
        color: #d9d9d9;
        padding: 0;
        background: #fff;
        margin-left: 0;
        margin-right: 32px;
        .ant-tabs-tab-btn:active {
          color: nth($gray, 1);
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: nth($gray, 1);
            font-weight: 700;
            text-shadow: unset;
          }
        }
        .ant-tabs-tab-btn {
          margin: 0 auto;
        }
      }
    }
    &.tabBoard {
      article {
        margin-bottom: 40px;
        p.tit {
          line-height: 1.2;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 28px;
        }
      }
    }
    // .sorting {
    //   margin-bottom: 34px;
    //   > .flex {
    //     margin-right: 60px;
    //     &:last-of-type {
    //       margin-right: 0;
    //     }
    //     p {
    //       font-weight: 700;
    //       margin-right: 20px;
    //       color: nth($gray, 1);
    //     }
    //   }
    // }
    .todayResult {
      ul {
        padding-bottom: 8px;
        gap: 22px;
        li {
          width: 25%;
          height: 180px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p.tit {
            margin-bottom: 0;
            color: nth($gray, 5);
          }
          p.number {
            font-weight: 700;
            font-size: 40px;
            color: nth($gray, 5);
            text-align: center;
          }
          em {
            font-weight: 700;
            &.surplus {
              color: #007559;
            }
            &.loss {
              color: #cf0000;
            }
          }
        }
      }
    }
    .resultTrend,
    .resultState {
      .box {
        padding: 20px 20px 33px;
      }
    }
    // .log {
    //   .sorting {
    //     position: relative;
    //     margin-bottom: 0;
    //     padding-bottom: 36px;
    //     margin-bottom: 38px;
    //     border-bottom: 2px solid #d9d9d9;
    //     > .flex:first-of-type {
    //       margin-bottom: 26px;
    //     }
    //   }
    //   .buttons {
    //     position: absolute;
    //     bottom: 36px;
    //     right: 0;
    //     display: flex;
    //     gap: 20px;
    //     button {
    //       font-weight: 700;
    //       width: 124px;
    //       height: 28px;
    //     }
    //   }
    // }
    .filterBtn {
      width: 124px;
      height: 32px;
      line-height: 32px;
      display: flex;
      justify-content: space-between;
      background: #ededed;
      border-radius: 5px;
      padding: 0 11px 0 20px;
      margin-left: 20px;
      color: nth($gray, 3);
      font-weight: 700;
    }
  }
  .pagination {
    position: absolute;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    margin: 30px 0 0;
    ul.ant-pagination {
      li {
        min-width: 28px;
        height: 28px;
        line-height: 28px;
        border: unset;
        a {
          color: nth($gray, 1);
        }
        .anticon {
          color: nth($gray, 1);
        }
        &.ant-pagination-item-active {
          background: transparent;
          a {
            text-decoration: underline;
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .contWrap {
    .contents {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
@media screen and (max-width: 1280px) {
}

// ant.design css 수정
.contWrap {
  .ant-select {
    &.error {
      .ant-select-selector {
        background: #ff436522 !important;
      }
    }
    &.ant-select-disabled {
      .ant-select-selector {
        color: nth($gray, 1) !important;
      }
    }
    .ant-select-arrow {
      color: nth($gray, 3);
    }
  }
  .ant-select-focused {
    .ant-select-selector {
      box-shadow: unset;
    }
  }
  .ant-select-selector {
    padding-left: 20px !important;
    background: #ededed !important;
    box-shadow: unset !important;
    border: unset !important;
    color: #000 !important;
    .ant-select-selection-placeholder,
    .ant-select-selection-search-input {
      color: #000 !important;
      font-weight: 700;
    }
  }
  .ant-radio-group {
    .ant-radio-button-wrapper-checked {
      background: nth($gray, 1);
      span {
        font-weight: 700;
        color: #fff !important;
      }
      &:hover {
        background: nth($gray, 1);
        border-color: nth($gray, 1);
      }
    }
    .ant-radio-button-wrapper {
      border: 1px solid nth($gray, 1);
      span {
        color: nth($gray, 1);
      }
      &:hover {
        color: nth($gray, 1);
      }
      &:before {
        background: nth($gray, 1);
      }
    }
  }
}
.ant-picker {
  border-radius: 5px !important;
  background: #ededed;
  border: unset;
  height: 32px;
  &.error {
    background: #ff436522;
  }
  .anticon {
    color: nth($gray, 1);
  }
  .ant-picker-input {
    color: nth($gray, 3);
    input {
      &::placeholder {
        color: nth($gray, 3);
      }
    }
  }
}
.ant-select-dropdown {
  .ant-select-item {
    text-align: center;
    color: nth($gray, 3);
  }
}
.dropdown {
  // max-height: 180px;
  // overflow-y: auto;
  &:hover {
    background: unset !important;
  }
  label {
    margin-bottom: 12px;
  }
}
.multiChk {
  flex-direction: column;
}
.ant-checkbox-wrapper {
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner:after {
      background-color: nth($primary, 1);
    }
  }
  &:hover {
    .ant-checkbox-inner {
      border-color: nth($primary, 1) !important;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: nth($primary, 1);
        background-color: nth($primary, 1);
      }
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: nth($primary, 1) !important;
      background-color: nth($primary, 1) !important;
    }
  }
}
.qnaOverlay,
.logoOverlay,
.utilityOverlay {
  .ant-dropdown-menu-item {
    span {
      button,
      a {
        font-size: 14px;
      }
    }
  }
}
.filterOverlay {
  button.apply {
    width: 100%;
    background: nth($primary, 1);
    &:hover {
      background: nth($primary, 1) !important;
    }
  }
}
.replyOverlay {
  ul {
    li {
      padding: 8px 14px !important;
      button {
        font-size: 14px;
      }
    }
  }
}
.utilityOverlay {
  .ant-dropdown-menu-item {
    padding: 0 !important;
    span {
      text-align: center;
      font-size: 14px;
      a,
      button {
        width: 100%;
        display: block;
        padding: 5px 12px;
      }
    }
  }
}
